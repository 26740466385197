var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"color":"transparent","flat":"","dark":""}},[_c('div',{staticClass:"text-h4 font-weight-bold text-center",staticStyle:{"padding":"3vh"}},[_vm._v("Announcement Panel")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"pa-2",attrs:{"rounded":"lg","width":"90%"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-card-subtitle',[_vm._v("Picked Ticket List")])],1),_c('v-spacer'),_c('v-col',{staticClass:"px-4 pt-2",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"color":"black","outlined":"","dense":"","hide-details":"","label":"Search","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ml-n3",attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(item.code))])]}},{key:"item.service",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getServiceNameByID(item.service_type_id))+" ")]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.remarks || 'N/A')+" ")]}},{key:"item.picked_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserNameByID(item.handled_by_user_id))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n1",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.announce(item.ticket_user_id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-bullhorn")])],1)]}}],null,true)},[_c('span',[_vm._v("Announce")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-n1",attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.showConfirmBox(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Complete")])])]}}],null,true)})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},model:{value:(_vm.is_show_confirm),callback:function ($$v) {_vm.is_show_confirm=$$v},expression:"is_show_confirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6"},[_vm._v(" Confirmation ")]),_c('v-card-text',[_vm._v(" Are you sure the complete the ticket? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-capitalize",attrs:{"disabled":_vm.is_confirm,"text":""},on:{"click":_vm.closeConfirmBox}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"text-capitalize",attrs:{"disabled":_vm.is_confirm,"color":"green","text":""},on:{"click":function($event){return _vm.completeTicket(_vm.selected_ticket)}}},[_vm._v(" Confirm ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }