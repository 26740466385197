<template>
  <v-container fluid>
    <!-- Title -->
    <v-row>
      <v-col cols="12">
        <v-card color="transparent" flat dark>
          <div class="text-h4 font-weight-bold text-center" style="padding: 3vh">Announcement Panel</div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row align="center" justify="center">
          <v-card rounded="lg" width="90%" class="pa-2">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card-subtitle>Picked Ticket List</v-card-subtitle>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" class="px-4 pt-2">
                <v-text-field color="black" outlined dense hide-details label="Search" v-model="search" append-icon="mdi-magnify"></v-text-field>
              </v-col>
            </v-row>
            <v-card-text>
              <v-data-table :headers="headers" :items="items" :search="search">
                <!-- Ticket Code -->
                <template v-slot:[`item.code`]="{ item }">
                  <v-chip class="ml-n3" label outlined>{{ item.code }}</v-chip>
                </template>

                <!-- Service Type Name -->
                <template v-slot:[`item.service`]="{ item }">
                  {{ getServiceNameByID(item.service_type_id) }}
                </template>

                <!-- Remarks -->
                <template v-slot:[`item.remarks`]="{ item }">
                  {{ item.remarks || 'N/A' }}
                </template>

                <!-- Picked By Staff Name -->
                <template v-slot:[`item.picked_by`]="{ item }">
                  {{ getUserNameByID(item.handled_by_user_id) }}
                </template>

                <!-- Announce button -->
                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon @click="announce(item.ticket_user_id)" class="mt-n1" color="error">
                        <v-icon small>fas fa-bullhorn</v-icon>
                      </v-btn>
                    </template>
                    <span>Announce</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon @click="showConfirmBox(item)" class="mt-n1" color="success">
                        <v-icon>mdi-check</v-icon>
                      </v-btn>
                    </template>
                    <span>Complete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>

    <!-- Complete Ticket Confirmation dialog -->
    <v-dialog persistent v-model="is_show_confirm" max-width="290">
      <v-card>
        <v-card-title class="text-h6"> Confirmation </v-card-title>
        <v-card-text> Are you sure the complete the ticket? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="is_confirm" class="text-capitalize" text @click="closeConfirmBox"> Cancel </v-btn>
          <v-btn :disabled="is_confirm" class="text-capitalize" color="green" text @click="completeTicket(selected_ticket)"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  name: 'Announcement',
  data() {
    return {
      is_show_confirm: false,
      is_confirm: false,
      selected_ticket: null,
      headers: [
        { text: 'Ticket', value: 'code', width: 120 },
        { text: 'Service', value: 'service' },
        { text: 'Counter', value: 'counter' },
        { text: 'Remarks', value: 'remarks' },
        { text: 'Picked By', value: 'picked_by', width: 200 },
        { text: 'Action', value: 'action', width: 120, align: 'center', sortable: false }
      ],
      items: [],
      search: null
    }
  },
  methods: {
    ...mapMutations(['SET_SNACKBAR']),
    ...mapActions(['announceTicket']),
    async getProcessingTicketList() {
      try {
        const response = await this.$api.getSpecificQueue('X')
        if (response.data.success) {
          response.data.data.queues.map(item => {
            const window = this.windows.find(window => window.window_id === item.window_id)
            return (item.counter = window ? `${window.name} ${window.name_en}` : null)
          })
          this.items = response.data.data.queues
        } else {
          this.SET_SNACKBAR({ status: true, color: 'error', text: 'Cannot fetch the processing ticket list, please try again later.', icon: 'close', timeout: '2000' })
        }
      } catch (error) {
        console.log(error.message)
        this.SET_SNACKBAR({ status: true, color: 'error', text: 'Server Occur Error', icon: 'close', timeout: '2000' })
      }
    },
    getServiceNameByID(service_type_id) {
      const service = this.service_type_list.find(service => service.service_type_id === service_type_id)
      return service.service_type
    },
    getUserNameByID(user_id) {
      const user = this.user_list.find(user => user.user_id === user_id)
      return user ? `${user.firstname} ${user.lastname}` : null
    },
    announce(ticket_user_id) {
      this.announceTicket({
        params: {
          ticket_user_id: ticket_user_id,
          status: 'calling'
        }
      })
    },
    completeTicket(ticket) {
      this.$store.dispatch('completeTicket', {
        params: {
          ticket: ticket,
          user_id: this.auth.user_info.user_id,
          ticket_user_id: ticket.ticket_user_id,
          status: 'completed'
        }
      })
      this.is_confirm = true
      setTimeout(() => {
        this.$router.push({ path: '/home' })
        this.closeConfirmBox()
      }, 1000)
    },
    showConfirmBox(ticket) {
      this.selected_ticket = ticket
      this.is_show_confirm = true
      this.is_confirm = false
    },
    closeConfirmBox() {
      this.selected_ticket = null
      this.is_show_confirm = false
      this.is_confirm = false
    }
  },
  computed: {
    ...mapState(['general', 'auth']),
    windows() {
      return this.general.windows
    },
    user_list() {
      return this.general.users
    },
    service_type_list() {
      return this.general.service_types
    }
  },
  mounted() {
    this.getProcessingTicketList()
  }
}
</script>
